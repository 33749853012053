<template>
  <div class="sidebar-wrapper sidebar-theme fixed-top">
    <div id="dismiss" class="d-lg-none"><i class="flaticon-cancel-12"></i></div>
    <nav id="sidebar">
      <ul class="navbar-nav theme-brand flex-row  d-none d-lg-flex">
        <li class="nav-item d-flex">
          <router-link :to="{name: 'dashboard'}" class="p-2">
            <img :src="baseUrl + 'assets/images/logo.png'" class="img-fluid brand-logo" alt="logo"  />
          </router-link>
        </li>
      </ul>

      <ul class="list-unstyled menu-categories" id="accordionExample">

        <li class="menu">
          <router-link :to="{name: 'dashboard'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div>
              <i class="flaticon-computer-6 ml-3"></i>
              <span>Dashboard</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && account.role !== 3">
          <a href="#ecommerce" data-toggle="collapse" aria-expanded="false" class="nav-item dropdown-toggle">
            <div class="">
              <i class="flaticon-layers"></i>
              <span>Create Campaign</span>
            </div>
            <div>
              <i class="flaticon-right-arrow"></i>
            </div>
          </a>
          <ul class="collapse submenu list-unstyled" id="ecommerce" data-parent="#accordionExample">
            <li :class="{'active': ['create_campaign_facebook'].includes($route.name)}">
              <router-link v-if="currentPlan && !currentPlan.is_influencer" :to="{name: 'create_campaign_facebook'}">Facebook ( Beta )</router-link>
              <a href="#"
                 v-else
                 class="text-muted"
                 @click.prevent="showInfluencerLicenseMessage">
                🚫 Facebook ( Beta )
              </a>
            </li>
            <li :class="{'active': ['google_wizard'].includes($route.name)}">
              <router-link v-if="currentPlan && !currentPlan.is_influencer" :to="{name: 'google_wizard'}">
                Google
              </router-link>
              <a href="#"
                 v-else
                 class="text-muted"
                 @click.prevent="showInfluencerLicenseMessage">
                🚫 Google</a>
            </li>
            <li :class="{'active': ['create_campaign_twitter'].includes($route.name)}">
              <router-link v-if="currentPlan && !currentPlan.is_influencer" :to="{name: 'create_campaign_twitter'}">
                X
              </router-link>
              <a href="#"
                 v-else
                 class="text-muted"
                 @click.prevent="showInfluencerLicenseMessage">
                🚫 X</a>
            </li>
            <li :class="{'active': ['create_influencer'].includes($route.name)}">
              <router-link :to="{name: 'create_influencer'}">
                Influencers/ Manual (Beta)
              </router-link>
            </li>
            <li>
              <a href="javascript:void(0)">
                Bing (Coming Soon)
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                TikTok (Coming Soon)
              </a>
            </li>
          </ul>
        </li>
        <li class="menu" v-if="account && account.role !== 3">
          <router-link :to="{name: 'mapping'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-elements"></i>
              <span>Link Existing Campaigns</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && account.role === 1">
          <router-link :to="{name: 'keyword_ranking'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-chart"></i>
              <span>Keyword Ranking</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="features && features.aweber">
          <router-link :to="{name: 'business_settings'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-settings-2"></i>
              <span>Business Settings</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="features && features.aweber">
          <router-link :to="{name: 'aweber_dashboard'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-chart-3"></i>
              <span>Aweber Dashboard</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && (account.role === 1 || account.role === 2)">
          <router-link :to="{name: 'settings'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-settings-1"></i>
              <span>Settings
                <i style="color: red!important; font-size: 12px"
                   v-if="settings && settings.google_user_id && !settings.google_ads_scope"
                   class="fas fa-circle"></i>
              </span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && (account.role === 1 || account.role === 2)">
          <router-link :to="{name: 'team'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-user-1"></i>
              <span>Team</span>
            </div>
          </router-link>
        </li>
        <li class="menu">
          <router-link :to="{name: 'contact_us'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-email-1"></i>
              <span>Contact Us</span>
            </div>
          </router-link>
        </li>
        <li class="menu text-center mt-5">
          <button class="btn btn-dark"
                  style="background-image: linear-gradient(94.3deg, #1a2140 10.9%, #3c416c 87.1%)"
                  v-if="account && account.role === 1 && !currentPlan && !popupShowing"
                  @click="$emit('showWelcomeModal')">Continue Onboarding</button>
        </li>
        <!-- <li class="menu">
            <router-link :to="{name: 'reports_spend'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
                <div class="">
                    <i class="flaticon-line-chart"></i>
                    <span>Report</span>
                </div>
            </router-link>
        </li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      features: 'auth/getFeatures',
      settings: 'settings/getSettings',
      account: 'auth/getAccount',
      currentPlan: 'plan/getCurrentPlan',
      popupShowing: 'plan/getPopupShowing'
    })
  },
  mounted() {
    window.$(".nav-item").on("click", function() {
      window.$(".dropdown-toggle").not(this).attr("aria-expanded", "false")
      window.$(".dropdown-toggle").not(this).addClass("collapsed")
      window.$(".list-unstyled").not(window.$(this).next("ul")).removeClass("show")
    })

  }
}
</script>